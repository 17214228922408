import React from "react"
import { styled } from "linaria/react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { colors } from "../../theme"

const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

const ArticleHead = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    @media screen and (max-width: 480px) {
      font-size: 35px;
    }
    margin-top: 25px;
    font-size: 44px;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #000;
  }
  span {
    text-transform: uppercase;
    font-weight: 300;
    color: #909090;
    margin-bottom: 5px;
    font-size: 16px;
  }
  > span:last-child {
    margin-bottom: 25px;
  }
`

const Body = styled.div`
  @media screen and (max-width: 480px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-right: auto;
  h1 {
    font-family: Arial, Helvetica, sans-serif;
    @media screen and (max-width: 480px) {
      font-size: 35px;
    }
    font-size: 44px;
    font-weight: 600;
    margin-top: 25px;
  }
  h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-weight: 600;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  h3 {
    font-family: Arial, Helvetica, sans-serif;
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
    font-size: 24px;
    font-weight: 500;
  }
  h4 {
    font-family: Arial, Helvetica, sans-serif;
    @media screen and (max-width: 480px) {
      font-size: 20px;
    }
    font-size: 24px;
    font-weight: 500;
  }
  p {
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* or 187% */

    color: #5b5b5b;
  }
  blockquote {
    @media screen and (max-width: 480px) {
      border-left: 5px solid #5b5b5b;
      padding-left: 15px;
    }
    margin-left: 0;
    border-left: 10px solid #5b5b5b;
    padding-left: 25px;
    > p {
      @media screen and (max-width: 480px) {
        font-size: 35px;
        line-height: 50px;
      }
      font-size: 44px;
      font-weight: 600;
      line-height: 73px;
      color: ${colors.black};
    }
  }
`

const Text = props => {
  const data = props.data
  return (
    <Wrapper>
      <ArticleHead>
        <h1>{data.tItle}</h1>
        <span>Published: {data.publishedDate}</span>
        <span>{data.category}</span>
      </ArticleHead>
      <Body>{renderRichText(data.body)}</Body>
    </Wrapper>
  )
}

export default Text
