import React from "react"
import { styled } from "linaria/react"
import Form from "../../Elements/SubForm"

const Box = styled.div`
  @media screen and (max-width: 1280px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;

  width: 100%;
  h3 {
    text-transform: uppercase;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    color: #7d7d7d;
    font-weight: 300;
  }
`

const Notification = () => {
  return (
    <Box>
      <h3>Get Notified</h3>
      <p>
        Get The Latest Updates About Our Mission, Ways to Support and
        Notifications When I Post Interesting Things.
      </p>
      <Form />
    </Box>
  )
}

export default Notification
