import React from "react"
import { styled } from "linaria/react"
import { colors } from "../../theme"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  > * {
    margin: 0 0 30px 0;
  }
  > *:last-child {
    margin: 0 0 0 0;
  }
  padding: 15px;

  width: 100%;
`

const Wrapper = styled.aside`
  @media screen and (max-width: 1280px) {
    display: none;
  }
  width: 100%;
  margin: 0 0 50px auto;
`
const Back = styled.div`
  background: ${colors.black};
  margin-right: auto;
  margin-top: auto;
  max-width: 215px;
  padding: 10px 10px;
  span {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
  }
`
const Aside = props => {
  return (
    <Wrapper>
      <Back>
        <span>RECENT POSTS</span>
      </Back>
      <Box>{props.children}</Box>
    </Wrapper>
  )
}

export default Aside
