import React from "react"
import { styled } from "linaria/react"
import Social from "../../Elements/Social"
import Img from "gatsby-image"

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 150px;
`
const SectionHeader = styled.div`
  display: flex;
  padding: 0 0;
  align-items: center;
  > span {
    font-size: 16px;
    line-height: 19px;
    color: #cccccc;
    text-transform: uppercase;
    font-weight: 300;
    min-width: 190px;
    margin-right: 15px;
  }
`

const Line = styled.div`
  height: 1px;
  background: #ccc;
  width: 100%;
  margin-left: auto;
`

const Portrait = styled(Img)`
  @media (max-width: 480px) {
    margin-bottom: 25px;
  }
  height: 186px;
  max-width: 186px;
  width: 100%;
  border-radius: 500px;
  margin-right: auto;
  img {
    object-fit: cover;
    object-position: top;
  }
`

const AuthorInfo = styled.div`
  @media (max-width: 480px) {
    flex-direction: column;
  }
  display: flex;
  padding: 20px 0;
`

const Text = styled.div`
  @media (max-width: 480px) {
    margin-left: 0;
  }
  width: 100%;
  margin-left: 25px;
  h4 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    font-weight: 300;
  }
`
const Author = props => {
  return (
    <Wrapper>
      <SectionHeader>
        <span>About The Author</span>
        <Line />
      </SectionHeader>
      <AuthorInfo>
        <Portrait
          imgStyle={{ objectFit: "", objectPosition: "" }}
          fluid={props.author.portrait.fluid}
        />
        <Text>
          <h4>{props.author.name}</h4>
          <p>{props.author.bio.bio}</p>
          <Social width="26px" height="26px" />
        </Text>
      </AuthorInfo>
      <Line />
    </Wrapper>
  )
}

export default Author
