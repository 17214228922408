import React from "react"
import { styled } from "linaria/react"

const Wrapper = styled.div`
  display: flex;
  > * {
    margin: 0 55px 0 0;
  }
  > *:last-child {
    margin: 0 0 0 0;
  }
  width: 100%;
  padding: 35px 55px;
  @media screen and (max-width: 1280px) and (min-width: 769px) {
    padding: 35px 55px;
    > * {
      margin: 0 0 0 0;
    }
    > *:last-child {
      margin: 0 0 0 0;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 15px;
    > * {
      margin: 0 0 0 0;
    }
    > *:last-child {
      margin: 0 0 0 0;
    }
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 935px;
  width: 100%;
`

const Right = styled.div`
  @media screen and (max-width: 1280px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  max-width: 383px;
  min-width: 260px;
  width: 100%;
`
const Content = props => {
  return (
    <Wrapper>
      <Left>{props.left}</Left>
      <Right>{props.right}</Right>
    </Wrapper>
  )
}

export default Content
