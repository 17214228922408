import React from "react"
import { styled } from "linaria/react"
import Link from "gatsby-link"
import Img from "gatsby-image"
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`

const Placeholder = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 200px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    color: #7d7d7d;
    font-weight: 300;
    margin-bottom: 10px;
  }
`

const StyledLink = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #000;
  &:visited {
    color: #000;
  }
`

const RecentPost = props => {
  const data = props.data.node
  return (
    <Wrapper style={props.style}>
      <Placeholder fluid={data.featuredImage.fluid} />
      <Content>
        <h3>{data.tItle}</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua...
        </p>
      </Content>
      <StyledLink to={`/blog/${data.slug}/`}>Read More</StyledLink>
    </Wrapper>
  )
}

export default RecentPost
