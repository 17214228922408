import React from "react"
import { styled } from "linaria/react"
import { colors } from "../../theme"
import arrow from "../../../images/mdi_keyboard_backspace.png"
import Link from "gatsby-link"
import Img from "gatsby-image"

const Wrapper = styled.div`
  width: 100%;
`

const Back = styled.div`
  @media screen and (max-width: 480px) {
    max-width: 129px;
    padding: 5px 10px;
  }
  background: ${colors.black};
  margin-right: auto;
  margin-top: auto;
  max-width: 159px;
  padding: 10px 10px;
  span {
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    margin-left: 10px;
  }
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 480px) {
    height: 300px;
  }
  width: 100%;
  background: #ccc;
  height: 467px;
  max-width: 907px;
`
const FeaturedImage = props => {
  return (
    <Wrapper>
      <Back>
        <Link to={"/blog/"} style={{ display: "flex", alignItems: "center" }}>
          <img src={arrow} alt="Back Arrow" />
          <span>BACK</span>
        </Link>
      </Back>
      <StyledImg fluid={props.fluid} />
    </Wrapper>
  )
}

export default FeaturedImage
